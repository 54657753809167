<!--
 * @Author: 智客云网络科技
 * @Date: 2022-02-12 01:10:46
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-17 12:24:17
 * @Description: 请输入文件描述信息
 * @FilePath: \src\views\App\Storage\Storage\PrintStorage.vue
-->
<template>
  <el-dialog
    v-drag2anywhere
    :title="title"
    :visible.sync="isShow"
    width="950px"
    top="5vh"
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    @close="_HandleClose()"
    custom-class="full-dialog"
  >
    <div class="vue-box" style="height: 75vh">
      <el-divider></el-divider>

      <el-button
        size="small"
        type="primary"
        icon="el-icon-check"
        @click="print()"
      >
        打印
      </el-button>
      <el-button size="small" icon="el-icon-close" @click="isShow = false">
        取消
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import printTemplate from "print-template";
export default {
  data() {
    return {
      title: "打印预览", //弹窗标题
      isShow: false, //控制弹窗是否显示
      template: null,
      printData: [], //打印数据
    };
  },
  watch: {
    isShow() {
      if (this.isShow) {
        setTimeout(() => {}, 3000);
      }
    },
  },
  methods: {
    // 打开
    open(id) {
      this.isShow = true; //显示组件
      this.id = id; //重写ID
      this.$nextTick(() => {
        this.loading = false;
      });
      this.setPrintData();
    },

    setPrintData() {
      this.printData = [
        { kst_tag_id: "CNFY-NXERXA", notes: "" },
        { kst_tag_id: "CNFY-9S5AZW", notes: "" },
        { kst_tag_id: "CNFY-8LIEFE", notes: "" },
        { kst_tag_id: "CNFY-91STF1", notes: "" },
        { kst_tag_id: "CNFY-GM78TQ", notes: "" },
        { kst_tag_id: "CNFY-H1YDO8", notes: "" },
      ];
    },

    print() {
      // 打印模板
      let data = [];
      for (let index = 0; index < 10; index++) {
        data.push({
          code: "test" + (index + 1),
          name: "测试" + (index + 1),
        });
      }
      this.template.print("expressDelivery1", data).then((pdf) => {
        if (pdf) {
          console.log("生成成功");
          let url = pdf.output("bloburi", { filename: "打印文件" });
          console.log(url);
        } else {
          console.log("生成失败");
        }
      });
    },

    setPrintTemplate() {
      //添加模板
      this.template.push({
        name: "expressDelivery1",
        size: [136, 76],
        fixed: [
          { type: "line", x: 2, y: 12, length: 72 },
          { type: "line", x: 2, y: 12, orientation: "p", length: 116 },
          { type: "line", x: 74, y: 12, orientation: "p", length: 116 },
          { type: "line", x: 2, y: 27, length: 72 },
          { type: "line", x: 2, y: 35, length: 72 },
          { type: "line", x: 2, y: 41, length: 52 },
          { type: "line", x: 54, y: 35, orientation: "p", length: 32 },
          { type: "line", x: 54, y: 49, length: 20 },
          { type: "line", x: 2, y: 59, length: 72 },
          { type: "line", x: 2, y: 67, length: 72 },
          { type: "line", x: 2, y: 77, length: 72 },
          { type: "line", x: 2, y: 110, length: 72 },
          { type: "line", x: 2, y: 128, length: 72 },
          {
            type: "text",
            fontSize: 3.8,
            fontWeight: 700,
            x: 66,
            y: 2,
            default: "货到\n付款",
          },
          {
            type: "qrcode",
            x: 25,
            y: 79,
            width: 25,
            default: "开发不易，记得start奥",
          },
        ],

        data: {
          name: { type: "text", x: 8, y: 45, fontSize: 3.5 },
          code: {
            type: "barcode",
            x: 7,
            y: 13,
            format: "CODE128A",
            width: 4,
            margin: 0,
            fontSize: 3.3,
            fontOptions: "bold",
            displayValue: true,
            height: 13,
          },
        },
      });
    },

    //关闭
    _HandleClose() {
      Object.assign(this.$data, this.$options.data());
      this.$parent._Load(); //通知父组件刷新数据
    },
  },
  mounted() {
    this.template = new printTemplate();
    this.setPrintTemplate();
  },
};
</script>
